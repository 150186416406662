import React, { useState } from "react";
import { Container, Row } from 'react-bootstrap';
import HeaderComponent from '../headerComponent/header-component';
import Footer from '../footer/footer';
import BodyComponent from './body-component'
import SuperAdminBody from "./super-admin-body";




const MainWrapper = () => {
    const [state, setState] = useState({ width: 'fullyOpened' });




    const data = [
        { organization: "ABC safty", contact: "Jhon Doe", Email: "demo@gmail.com", mobileNo: "0123456789", expiryDate: "11/10/2021" },
        { organization: "ABC safty", contact: "Jhon Doe", Email: "demo@gmail.com", mobileNo: "0123456789", expiryDate: "11/10/2021" },
        { organization: "ABC safty", contact: "Jhon Doe", Email: "demo@gmail.com", mobileNo: "0123456789", expiryDate: "11/10/2021" }
    ];


    return (
        <>
            <Container fluid >
                <Row>
                    <HeaderComponent state={state} setState={setState} />
                    <BodyComponent SuperAdminBody={<SuperAdminBody state={state} data={data} />} />
                    <Footer />
                </Row>
            </Container>
        </>
    )
}
export { MainWrapper };