import React, { useState, useEffect } from 'react'
import { messages } from "../utils/constants";
import { Accordion, Container, Row, Col, Form } from "react-bootstrap";
import Spinner from "../spinner/spinner";
import ShowEntries from "../utils/show-entries";
import Pagination from "react-js-pagination";
import GridFilter from "../grid/grid-filter";
import axios from "axios";
import { ReactTitle } from "react-meta-tags";




const Assessments = () => {
    const role = localStorage.getItem("role");
    const trainingLogId = localStorage.getItem("trainingLogId");
    const trainingLogName = localStorage.getItem("trainingLogName") || "";
    const [spinner, setSpinner] = useState(false);
    const [itemsCountPerPage, setItemsCountPerPage] = useState(
        messages.GridRowCountOptions[0]
    );
    const [searchContent, setSearchContent] = useState("");

    const [type, setType] = useState();
    const [totalCount, setTotalCount] = useState();
    const [activePage, setActivePage] = useState(1);
    const [skip, setSkip] = useState(0);
    const [accordion, setAccordion] = useState([
        // { id: 1, name: "name" }, { id: 2, name: "name2" }
    ]);
    const [trainingId, setTrainingId] = useState(trainingLogId);
    const [trainingName, setTrainingName] = useState(trainingLogName);
    const [allow, setAllow] = useState();
    const [isChecked, setIsChecked] = useState(false);
    const [isCheckedAll, setIsCheckedAll] = useState(false);
    const [reportAccess, setReportAccess] = useState(false);


    useEffect(() => {
        getTrainingAssessments();
    }, [])

    const getTrainingAssessments = () => {
        const url = "/training/getTrainingAssessments";
        setSpinner(true);
        axios.post(url, { id: trainingId })
            .then((res) => {
                setSpinner(false);
                if (res.data) {
                    let rows = [];
                    let data = res.data;
                    for (let i in data) {
                        let trainingAssessmentData = {};
                        trainingAssessmentData.reportAccess = reportAccess;
                        trainingAssessmentData.name = data[i].name;
                        trainingAssessmentData.email = data[i].email;
                        trainingAssessmentData.macId = data[i].macId;
                        trainingAssessmentData.questions = data[i].questions;
                        trainingAssessmentData.createdBy = data[i].createdBy;
                        trainingAssessmentData.correctAnsweredCount = data[i].correctAnsweredCount;
                        trainingAssessmentData.totalQuestionCount = data[i].totalQuestionCount;
                        rows.push(trainingAssessmentData);
                    }
                    setAccordion(rows);
                }
                setTotalCount(res.data.totalCount)
            }).catch((error) => {
                console.log(error);
                setSpinner(false);
            })
    }
    const reportAccessForAll = () => {
        let array2 = [...accordion];
        accordion.map((rt, i) => {
            array2[i].reportAccess = !isChecked;
        })
        setIsChecked(!isChecked)
        setAccordion(array2)
    }
    const reportAccessFn = (i) => {
        let array = [...accordion];
        console.log(i, !accordion.reportAccess)
        array[i].reportAccess = !array[i].reportAccess;
        setAccordion(array);
    }

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
        setSkip(pageNumber * itemsCountPerPage.value - itemsCountPerPage.value);
    };
    console.log("accordion", accordion)
    const Acc = accordion.map((x, i) => (<Accordion className="my-4 assessment-accordion">
        <Accordion.Item eventKey={i} >
            <Accordion.Header>
                <Col lg={12} md={12} sm={12} xs={12}>
                    <Row>
                        {/* for report access- to be implemented later 
                {((role === "1") || (role === "2")) ? <Col lg={3}>
                    <Form>
                        <Form.Check.Input aria-label="option 1" className="mx-1" checked={x.reportAccess} onChange={() => reportAccessFn(i)} onClick={(event) => {
                            event.stopPropagation();
                        }
                        } />
                        <Form.Check.Label><h6>Allow</h6></Form.Check.Label>
                    </Form>
                </Col> : ""} 
                <Col lg={((role === "1") || (role === "2")) ? 2 : 4}><h6>{x.name}</h6></Col>
                <Col lg={((role === "1") || (role === "2")) ? 3 : 4}><h6>{x.email}</h6></Col>
                */}
                        <Col lg={3} md={3} sm={3} xs={3} className="bold"><h6>{x.name}</h6></Col>
                        <Col lg={4} md={4} sm={4} xs={4} className="bold"><h6>{x.email}</h6></Col>
                        <Col lg={3} md={3} sm={3} xs={3} className="bold"><h6>{x.macId}</h6></Col>
                        <Col lg={2} md={2} sm={2} xs={2} className="bold"><h6>{x.correctAnsweredCount + "/" + x.totalQuestionCount}</h6></Col>
                    </Row>
                </Col>
            </Accordion.Header>
            <Accordion.Body>
                {x.questions.map((st, j) => (<>
                    <Row className="my-1">
                        <Col lg={2} md={2} sm={2} xs={2}>Question {j + 1} :</Col>
                        <Col lg={10} md={10} sm={10} xs={10}>{st.question}</Col>
                    </Row>
                    <Row>
                        <Col lg={2} md={2} sm={2} xs={2}>Right Answer :</Col>
                        <Col lg={10} md={10} sm={10} xs={10} className="word-wrap bold">
                            {st.rightAnswer}
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={2} md={2} sm={2} xs={2}>Given Answer :</Col>
                        <Col lg={10} md={10} sm={10} xs={10} className={(st.givenAnswer === st.rightAnswer) ? "rightAnsBrd bold word-wrap" : "wrongAnsBrd bold word-wrap"}>
                            {st.givenAnswer}
                        </Col>
                    </Row>
                    <hr />
                </>))}
            </Accordion.Body>
        </Accordion.Item>
    </Accordion >))

    return (
        <Container fluid className="px-0 body-component">
            <ReactTitle title="Home-Assessments" />
            <div className="container-fluid mt-3">
                <div className="row">
                    <p className="font-bold mb-1"> {messages.Assessments}</p>
                </div>
                <hr />
                <div className="row my-2">
                    {/* <div className="col-sm-6"><p className="font-bold mb-1">Training id : {trainingId}</p></div> */}
                    <div className="col-sm-6"><p className="font-bold mb-1 ellipsis-text">Training name : {trainingName}</p></div>
                </div>
                {/* <div className="row my-5">
                    <GridFilter
                        role={role}
                        entries={false}
                        search={false}
                        restore={false}
                        //  restoreFunction={restoreFunction}
                        itemsCountPerPage={itemsCountPerPage}
                        setItemsCountPerPage={(e) => {
                            setSpinner(true)
                            setSkip(0)
                            setItemsCountPerPage(e);
                            setActivePage(1);
                            setSpinner(false)
                        }}
                        // ajaxCall={getSensorList}
                        setSearchContent={(e) => {
                            setSpinner(true)
                            setSkip(0)
                            setSearchContent(e);
                            setSpinner(false)
                        }}
                        setSkip={setSkip}
                        setActivePage={setActivePage}

                    />
                </div> */}
                <div className="container-fluid grid-list-div mb-3 ">
                    <div className="row mt-3 accessment-headings">

                        {/* for report access- to be implemented later  
                        {((role === "1") || (role === "2")) ? <Col lg={3}>
                            <Form>
                                <Form.Check.Input aria-label="option 1" className="mx-1" isChecked={isCheckedAll} onChange={reportAccessForAll} />
                                <Form.Check.Label><h6>Report Access</h6></Form.Check.Label>
                            </Form>
                        </Col> : ""}
                        <Col lg={((role === "1") || (role === "2")) ? 2 : 4}><h6>Name</h6></Col>
                        <Col lg={((role === "1") || (role === "2")) ? 3 : 4}><h6>Email</h6></Col> */}
                        <Col lg={3} md={3} sm={3} xs={3}><h6>Name</h6></Col>
                        <Col lg={4} md={4} sm={4} xs={4}><h6>Email</h6></Col>
                        <Col lg={3} md={3} sm={3} xs={3}><h6>Device</h6></Col>
                        <Col lg={2} md={2} sm={2} xs={2}><h6 className="">Score</h6></Col>
                    </div>
                    <div className="row organization-table">
                        {
                            spinner ? (<Spinner />) :
                                Acc
                        }
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default Assessments
