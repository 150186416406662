import React, { useState, useEffect } from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import axios from 'axios'

const SupportMenual = () => {

    const [type, setType] = useState(4);
    const [videos, setVideos] = useState([{}]);

    useEffect(() => {
        getSupportDocuments();
    }, [type])



    const getSupportDocuments = () => {
        axios.post("/documents/getDocumentList", { type: type }).then((res) => {
            if (res.data) {
                console.log("res", res)
                setVideos(res.data)
            }
        })
    }


    const VideosData = videos.map((mn) => {
        // console.log("path", mn.content)
        const openVideo = () => {
            console.log("mn content", mn.content)
            window.open(mn.content);
        }
        return (<Col lg={6}>

            <Row className="text-center manual pointer" onClick={openVideo}>
                <Col lg={3} className="px-0">
                    <div className="manualIcon">
                        <img src="/icons/Video-play-s.svg" alt="reference" />
                    </div>
                </Col>
                <Col lg={9} className="manualName">
                    <h6 data-bs-toggle="tooltip" data-bs-placement="top" title={mn.name ? mn.name : ""}>{mn.name ? `${mn.name.slice(0, 15)} ${mn.name.length > 15 ? "..." : ""}` : ""}</h6>
                </Col>
            </Row>
        </Col>)

    })


    return (
        <>
            <Container className="manuals-root">
                <Row>
                    <h6 className="text-center manual-heading">Videos</h6>
                </Row>
                <hr />
                <Row className="mt-4 manual-data">
                    {VideosData}
                </Row>
            </Container>

        </>
    )
}

export default SupportMenual
