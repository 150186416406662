import { React } from "react";
import Organizations from "./organizations";
import User from "./User";
import Meters from "./meters";
import AddUpdateMeter from "./add-meter";
import AddOrganization from "./add-organization";
import AddUser from "./add-user";
import Logout from "../logout/logout";
import SideMenu from "./side-menus/side-menu";
import { Route } from "react-router-dom";
import SensorList from "./sensor-list";
import AddSensor from "./add-sensor";
import AddUpdateQuestionSet from "./add-question-set";
import QuestionSets from "./question-set-list";
import DevicesList from "./device-list";
import AlarmList from "./alarm-list";
import Profile from "./profile";
import TrainingList from "./training-list";
import TrainingLogViewer from "./training-log-viewer";
import Support from "./support";
import Assessments from "./training-assessments";
import AboutHazsim from "./about-hazsim";
import DefaultStudentNamesList from "./default-student-names-list.jsx";
import Settings from "./Settings";

function OrganizationBody({ state, data }) {
  return (
    <div className="d-flex">
      <SideMenu state={state} />
      <Route
        path="/Home/Organizations"
        exact
        render={(props) => <Organizations data={data} />}
      ></Route>
      <Route
        path="/Home/User/add-user"
        exact
        render={(props) => <AddUser data={data} />}
      ></Route>
      <Route
        path="/Home/Organizations/add-update-organization"
        exact
        render={(props) => <AddOrganization data={data} />}
      ></Route>
      <Route
        path="/Home/User"
        exact
        render={(props) => <User data={data} />}
      ></Route>
      <Route
        path="/Home/Sensor"
        exact
        render={(props) => <SensorList />}
      ></Route>
      <Route
        path="/Home/Sensor/add-update-sensor"
        exact
        render={(props) => <AddSensor />}
      ></Route>
      <Route path="/Home/Meter" exact render={(props) => <Meters />}></Route>
      <Route
        path="/Home/Meter/add-update-meter"
        exact
        render={(props) => <AddUpdateMeter data={data} />}
      ></Route>
      <Route
        path="/Home/Questionnaire"
        exact
        render={(props) => <QuestionSets data={data} />}
      ></Route>
      <Route
        path="/Home/QuestionSet/add-question-set"
        exact
        render={(props) => <AddUpdateQuestionSet data={data} />}
      ></Route>
      <Route
        path="/Home/Devices"
        exact
        render={(props) => <DevicesList data={data} />}
      ></Route>
      <Route
        path="/Home/Alarms"
        exact
        render={(props) => <AlarmList data={data} />}
      ></Route>
      <Route
        path="/Home/Profile"
        exact
        render={(props) => <Profile data={data} />}
      ></Route>
      <Route
        path="/Home/Trainings"
        exact
        render={(props) => <TrainingList data={data} />}
      ></Route>
      <Route
        path="/Home/Training-log-viewer"
        exact
        render={(props) => <TrainingLogViewer data={data} />}
      ></Route>
      <Route
        path="/Home/Support"
        exact
        render={(props) => <Support data={data} />}
      ></Route>
      <Route
        path="/Home/Trainings/Assessments"
        exact
        render={(props) => <Assessments data={data} />}
      ></Route>
      <Route
        path="/Home/AboutHazSim"
        exact
        render={(props) => <AboutHazsim data={data} />}
      ></Route>
      <Route
        path="/Home/DefaultStudentNames"
        exact
        render={(props) => <DefaultStudentNamesList data={data} />}
      ></Route>
      <Route path="/Home/Logout" exact render={(props) => <Logout />}></Route>
      <Route
        path="/Home/Settings"
        exact
        render={(props) => <Settings />}
      ></Route>
    </div>
  );
}
export default OrganizationBody;
