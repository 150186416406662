import { React, useState, useEffect } from "react";
import { Col, Container, Form } from "react-bootstrap";
import { messages } from "../utils/constants";
import GridFilter from "../grid/grid-filter";
import ReactGrid from "../grid/reactGrid";
import ShowEntries from "../utils/show-entries";
import Pagination from "react-js-pagination";
import ActionConfirmationPopup from "../shared/action-confirmation-popup";
import { notify } from "../utils/utility";
import Spinner from "../spinner/spinner";

import { useHistory } from "react-router-dom";
import axios from "axios";
import { ReactTitle } from "react-meta-tags";
import { Modal } from 'react-bootstrap';
import Select from 'react-select';

function Meters({ state }) {
    const userId = localStorage.getItem("userId");
    const organizationId = localStorage.getItem("organizationId");
    const role = localStorage.getItem("role");
    const history = useHistory();
    const [searchContent, setSearchContent] = useState("");
    const [activePage, setActivePage] = useState(1);
    const [itemsCountPerPage, setItemsCountPerPage] = useState(
        messages.GridRowCountOptions[0]
    );
    const [showTotalCount, setShowTotalCount] = useState(true);

    const [rowData, setRowData] = useState([]);
    const [gridApiData, setGridApiData] = useState();
    const [totalCount, setTotalCount] = useState(50);
    const [skip, setSkip] = useState(0);
    const [show, setShow] = useState(false);
    const [meterName, setMeterName] = useState("");
    const [meterId, setMeterId] = useState("");
    const [meterStatus, setMeterStatus] = useState("");
    const [spinner, setSpinner] = useState(false);
    const [category, setCategory] = useState("");
    const [assignToOrganization, setAssignToOrganization] = useState(true)
    const [selectedRows, setSelectedRows] = useState([])
    const [organizationSelect, setOrganizationSelect] = useState(false)
    const [options, setOptions] = useState([]);
    const [organizationList, setOrganizationList] = useState([]);
    const [selectedOrganization, setSelectedOrganization] = useState("");
    const [selectedOrganizationName, setSelectedOrganizationName] = useState("");
    const meterDisabledForOrg = localStorage.getItem("meterDisabledForOrg") === 'true';
    const [filterOrganizationId, setFilterOrganizationId] = useState(null);
    useEffect(() => {
        getMeterList();
    }, [category, filterOrganizationId]);

    function GridActions(props) {
        const status = () => {
            setOpen(props.data);
        };
        const edit = (e) => {
            localStorage.setItem("editMeterId", props.data.id);
            history.push("/Home/Meter/add-update-meter");
        };
        const copyNdEdit = () => {
            localStorage.setItem("copyMeterId", props.data.id);
            history.push("/Home/Meter/add-update-meter");
        };

        return (
            < ul className="trigger-div mb-0 px-0">
                {/* {(((parseInt(role) === 1) && (props.data.isDefault)) || ((props.data.organizationId === organizationId) && (parseInt(role) === 2))
                    || (props.data.createdBy === userId))
                    ? <i className="mx-4 fas fa-pencil-alt color-orange pointer" onClick={edit}></i>
                    : <i className="mx-4 fas fa-pencil-alt color-orange pointer sensor-edit" disabled></i>} */}
                <i className="mx-4 fas fa-pencil-alt color-orange pointer" onClick={edit}></i>
                {!meterDisabledForOrg &&
                    <i className="fas fa-copy color-orange pointer" data-bs-toggle="tooltip" data-bs-placement="top" title="Copy" onClick={copyNdEdit} ></i>}
                <i className="mx-4 fas fa-trash color-orange pointer" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete" onClick={status}></i>
            </ul>
        );
    }
    const setOpen = (data) => {
        handleShow();
        setMeterName(data.meter);
        setMeterId(data.id);
        setMeterStatus(data.status);
    };
    const handleClose = () => setShow(false);
    const handleOrganizationClose = () => {
        setOrganizationSelect(false)
        setSelectedOrganization("")
    }
    const handleShow = () => {
        setShow(true);
    };
    const deleteFunction = (id) => {
        const data = { meterId: id };
        axios.post("/Meters/deleteMeter", data).then((res) => {
            setSpinner(true);
            if (!res.data.errorCode) {
                setSpinner(false);
                notify(messages.ToastSuccess, messages.meterDeleteSuccessfully);
                getMeterList();
                handleClose();
            } else {
                setSpinner(false);
                notify(messages.ToastError, messages.somethingWentWrong);
                handleClose();
            }
        });
    };
    const restoreFunction = () => {
        setSpinner(true);
        axios
            .post("/Meters/restoreDefault")
            .then((res) => {
                if (res) {
                    setSpinner(false);
                    getMeterList();
                    setSpinner(false);
                }
            })
            .catch((error) => {
                console.log(error);
                setSpinner(false);
            });
    };

    function MeterLabel(props) {
        return (
            <>
                {props.data.meter}
                <span className="elementDefault">{(parseInt(role) === 1 && props.data.isDefault) || (props.data.organizationId === organizationId && parseInt(role) === 2)
                    || props.data.createdBy === userId ? "" : "Default"}</span>
            </>
        );
    }

    useEffect(() => {
        getMeterList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [skip, itemsCountPerPage]);

    const getMeterList = () => {
        const url = "/Meters/getMeterList";
        setSpinner(true);
        axios
            .post(url, {
                // accessToken: accessToken,
                skip: skip,
                limit: itemsCountPerPage.value,
                searchContent: searchContent,
                category: category?.toString?.()?.match(/^\d$/i) ? category : null,
                organization: filterOrganizationId?.toString?.() ? filterOrganizationId : null,
                fields: [
                    "id",
                    "name",
                    "skin",
                    "sensors",
                    "isDefault",
                    "status",
                    "organizationId",
                    "createdBy",
                    "category",
                ],
                showTotalCount: showTotalCount,
            })
            .then((res) => {
                if (res.data) {
                    setSpinner(false);
                    let rows = [];
                    let data = res.data.items;
                    for (let i in data) {
                        let sensors = data[i].sensors;
                        let sensorsLabels = [];
                        for (let k = 0; k < sensors.length; k++) {
                            sensorsLabels.push(sensors[k].label);
                        }
                        let skinName =
                            messages.skinType.filter((inst) => {
                                return +inst.id === data[i].skin;
                            })[0].name || "";
                        let item = {};
                        item.skin = skinName;
                        item.meter = data[i].name;
                        item.category = messages.categoryValues[data[i].category] || "--";
                        item.sensor = sensorsLabels;
                        item.id = data[i].id;
                        item.isDefault = data[i].isDefault;
                        item.status = data[i].status;
                        item.organizationId = data[i].organizationId;
                        item.createdBy = data[i].createdBy;
                        rows.push(item);
                    }
                    setRowData(rows);
                }
                setTotalCount(res.data.totalCount);
            })
            .catch((error) => {
                console.log(error);
                setSpinner(false);
            });
    };
    const onGridReady = (params) => {
        setGridApiData(params.api);
    };
    useEffect(() => {
        setTimeout(() => gridApiData?.forEachNode?.(node => {
            node.setSelected(selectedRows.some(selectedRow => selectedRow.id === node.data.id))
        }), 500);
    }, [rowData, gridApiData]);
    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
        setSkip(pageNumber * itemsCountPerPage.value - itemsCountPerPage.value);
    };
    const columnDefs = [].concat(
        +role === 1 ? {
            field: "_id",
            maxWidth: 40,
            headerName: "",
            headerClass: "ag-custom-header",
            headerCheckboxSelection: true,
            checkboxSelection: true,
            showDisabledCheckboxes: true,
        } : [],
        {
            field: "skin",
            headerName: "Skin",
            headerClass: "ag-custom-header",
            minWidth: 180,
        },
        {
            field: "meter",
            headerName: "Meter",
            headerClass: "ag-custom-header",
            minWidth: 180,
            cellRendererFramework: MeterLabel,
        },
        {
            field: "sensor",
            headerName: "Sensor",
            headerClass: "ag-custom-header",
            minWidth: 180,
        },
        +role === 1
            ? {
                field: "category",
                headerName: "Category",
                headerClass: "ag-custom-header",
                minWidth: 180,
            }
            : [],
        {
            field: "action",
            cellRendererFramework: GridActions,
            pinned: "right",
            headerClass: "ag-custom-header",
            headerName: "Actions",
            maxWidth: 200,
        }
    );
    const containerStyle = {
        boxSizing: "border-box",
        height: rowData.length * messages.GridRowheight + messages.GridHeaderheight,
        minHeight: 150,
        width: "calc(100%)",
    };
    const onRowSelect = (params) => {
        if (params?.type === "rowSelected") {
            if (params.node.selected) {
                params?.data?.id && setSelectedRows(prev => {
                    if (!prev?.some?.(i => i.id === params.data.id)) return [...prev, { id: params.data.id, meter: params.data.meter }];
                    return prev;
                });
            } else {
                setSelectedRows(prev => prev.filter(selectedRow => selectedRow.id !== params.data.id));
            }
        }
    };
    useEffect(() => {
        setAssignToOrganization(!selectedRows.length);
        const options = organizationList?.map(item => ({ value: item.name, label: item.name, id: item.id }));
        setOptions(options);
    }, [selectedRows, organizationList]);
    const getOrganizationList = () => {
        const url = "/Organizations/getOrganizationList";
        axios.post(url, {
            skip: 0,
            limit: 0,
            searchContent: "",
            status: 1,
        }).then((res) => {
            setOrganizationList(res.data.items);
        }).catch((error) => {
        })
    }
    const setUserChoice = (choice) => {
        setSelectedOrganization(choice.id)
        setSelectedOrganizationName(choice.value)
    }
    const submitHandler = () => {
        const selectedOrganizationId = organizationList?.filter(item => item.id === selectedOrganization).map(item => ({ id: item.id }))
        const selectedRowsId = selectedRows.map(item => ({ id: item.id }))
        // API needs to be checked
        const url = "/Meters/assignMetersToOrganization";
        axios.post(url, {
            organizationIds: selectedOrganizationId,
            metersIds: selectedRowsId,
        }).then((res) => {
            res = JSON.parse(res.data);
            if (res?.result === 'done') {
                notify(messages.ToastSuccess, `Meter assigned to ${selectedOrganizationName}`);
                handleOrganizationClose();
            } else {
                notify(messages.ToastError, "Error");
            }
        }).catch((error) => {
            notify(messages.ToastError, error.message);
        })
    }
    useEffect(() => {
        +role === 1 && getOrganizationList()
        localStorage.removeItem("editMeterId");
        localStorage.removeItem("copyMeterId");
    }, []);

    return (
        <Container fluid className="px-0 body-component">
            <ReactTitle title="Home-Meters" />
            <ActionConfirmationPopup
                handleClose={handleClose}
                handleShow={handleShow}
                setShow={setShow}
                show={show}
                message={
                    show &&
                    meterStatus === 1 &&
                    messages.deleteSensorMessage + ` "${meterName}"  meter?`
                }
                action={show && meterStatus === 1 && messages.delete}
                deactivateFunction={() => deleteFunction(meterId)}
            />
            <div className="container-fluid mt-3">
                <div className="row">
                    <p className="font-bold mb-1"> {messages.Meters}</p>
                </div>
                <hr />
                <div className="row mb-3">
                    <GridFilter
                        entries
                        search
                        role={role}
                        ajaxCall={getMeterList}
                        itemsCountPerPage={itemsCountPerPage}
                        setItemsCountPerPage={(e) => {
                            setSpinner(true);
                            setSkip(0);
                            setItemsCountPerPage(e);
                            setSpinner(false);
                        }}
                        // setStatus={setStatus}
                        // status={status}
                        restore={true}
                        restoreFunction={restoreFunction}
                        addBtn={!meterDisabledForOrg}
                        addBtnPath={"/Home/Meter/add-update-meter"}
                        setSearchContent={(e) => {
                            setSpinner(true);
                            setSkip(0);
                            setSearchContent(e);
                            setSpinner(false);
                        }}
                        tooltipTitle={"Meter"}
                        setSkip={setSkip}
                        setActivePage={setActivePage}
                    />
                </div>
                {+role === 1 && <div className="row mb-5">
                    <div className="col-lg-4 col-md-4">
                        <div className="grid-row-count">
                            <span className="label-content">{messages.Organization}</span>
                            <span>
                                <Form.Select
                                    aria-label="Default select example"
                                    value={filterOrganizationId}
                                    default={undefined}
                                    onChange={(e) => {
                                        handlePageChange(1)
                                        setFilterOrganizationId(e.target.value);
                                    }}
                                >
                                    {[
                                        <option
                                            key={"organization-select"}
                                            value={""}
                                        >
                                            Select Organization
                                        </option>,
                                        options.map((e, key) => {
                                            return (
                                                <option
                                                    key={"organization-" + key}
                                                    selected={e.value == filterOrganizationId}
                                                    value={e.id}
                                                >
                                                    {e.label}
                                                </option>
                                            );
                                        })]}
                                </Form.Select>
                            </span>
                        </div>
                    </div>
                    <div className="category w-auto">
                        <div className="grid-row-count">
                            <span className="label-content">{messages.Category}</span>
                            <span>
                                <Form.Select
                                    aria-label="Default select example"
                                    value={category}
                                    default={undefined}
                                    onChange={(e) => {
                                        handlePageChange(1)
                                        setCategory(e.target.value);
                                    }}
                                >
                                    {messages.category.map((e, key) => {
                                        return (
                                            <option
                                                key={"category-" + key}
                                                selected={e.value == category}
                                                value={e.value}
                                            >
                                                {e.label}
                                            </option>
                                        );
                                    })}
                                </Form.Select>
                            </span>
                        </div>
                    </div>
                    <button
                        disabled={assignToOrganization}
                        className="btn btn-secondary mr-sm-3 organization-plus-select pointer w-auto"
                        onClick={() => setOrganizationSelect(true)}
                        type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Assign to organization ">
                        <i className="fas fa-link"></i>
                    </button>
                    <Modal show={organizationSelect} onHide={handleOrganizationClose}>
                        <div style={{ paddingLeft: "2rem", paddingRight: "2rem", paddingTop: "1rem", paddingBottom: "1rem" }}>
                            <Modal.Header closeButton className="py-0  px-0 Heading-for-modal" ><p style={{ fontSize: "1.3rem" }}>{messages.organizationSelect}</p>
                            </Modal.Header>
                            <Modal.Body className="px-0 pb-0"><div>
                                <Select options={options} onChange={(choice) => setUserChoice(choice)} />
                            </div></Modal.Body>
                            <Modal.Footer className="px-0">
                                <div className="submitAndCloseBtn">
                                    <button
                                        className={`saveBtn m-1 submitSaveBtn forgot-password-button `}
                                        variant="primary"
                                        disabled={!selectedOrganization}
                                        onClick={() => submitHandler()}
                                    >
                                        {messages.submit}
                                    </button>
                                    <button
                                        className="button-custom m-1 closeBtn"
                                        variant="primary"
                                        onClick={handleOrganizationClose}
                                    >
                                        {messages.cancel}
                                    </button>
                                </div>
                            </Modal.Footer>
                        </div>
                    </Modal >
                </div>}
                <div className="container-fluid grid-list-div mb-3 ">
                    <div className=" meter-table">
                        <div className="ag-theme-alpine application-grid">
                            {spinner ? (
                                <Spinner />
                            ) : (
                                <ReactGrid
                                    id="metersGrid"
                                    gridId="meters-List"
                                    containerStyle={containerStyle}
                                    checkboxSelection
                                    columnDefs={columnDefs}
                                    rowSelection="multiple"
                                    suppressRowClickSelection
                                    onGridReady={onGridReady}
                                    onRowSelect={onRowSelect}
                                    rowData={rowData}
                                />
                            )}
                        </div>
                        {totalCount && (<div className="row mb-5">
                            <div className="col-sm-6 font-bold mt-2 d-flex gx-1">
                                <ShowEntries
                                    totalCount={totalCount}
                                    itemsCountPerPage={itemsCountPerPage.value}
                                    activePage={activePage}
                                />
                                {+role === 1 && <div className="mx-1"> | Selected {selectedRows.length} of  {totalCount}</div>}
                            </div>
                            {totalCount > 10 && (
                                <div className="col-md-6 pagination-component">
                                    <Pagination
                                        activePage={activePage}
                                        itemsCountPerPage={itemsCountPerPage.value}
                                        totalItemsCount={totalCount}
                                        pageRangeDisplayed={2}
                                        onChange={handlePageChange}
                                        itemClass="pagination-item"
                                        linkClass="pagination-link"
                                        prevPageText="Previous"
                                        nextPageText="Next"
                                    />
                                </div>
                            )}
                        </div>)}
                    </div>
                </div>
            </div>
        </Container>
    );
}
export default Meters;
