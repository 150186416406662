import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { messages } from "../utils/constants";
import { Container } from "react-bootstrap";
import GridFilter from "../grid/grid-filter";
import Spinner from "../spinner/spinner";
import axios from "axios";
import ReactGrid from "../grid/reactGrid";
import ShowEntries from "../utils/show-entries";
import Pagination from "react-js-pagination";
import { notify } from '../utils/utility'

import ActionConfirmationPopup from "../shared/action-confirmation-popup";
import { ReactTitle } from "react-meta-tags";

const Questionnaire = () => {
    const history = useHistory()
    const role = localStorage.getItem("role");
    const [spinner, setSpinner] = useState(false);
    const [itemsCountPerPage, setItemsCountPerPage] = useState(
        messages.GridRowCountOptions[0]
    );
    const [questionSetName, setQuestionSetName] = useState("");
    const [questionSetId, setQuestionSetId] = useState("");
    const [questionSetStatus, setQuestionSetStatus] = useState("");
    const [show, setShow] = useState(false);

    const [totalCount, setTotalCount] = useState();
    const [activePage, setActivePage] = useState(1);
    const [showTotalCount, setShowTotalCount] = useState(true);

    const [searchContent, setSearchContent] = useState("");
    const [status, setStatus] = useState(1);
    const [skip, setSkip] = useState(0);


    // const staticCellStyle = { color: "#FF7538" };
    const [rowData, setRowData] = useState([]);

    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: "Set Name",
            field: "name",
            headerClass: "ag-custom-header",
            cellRendererFramework: GridActionsElement,
            maxWidth: 800,
            minWidth: 120,
        },
        {
            headerName: "Number of Questions",
            field: "question",
            headerClass: "ag-custom-header",
            minWidth: 150
        },

        {
            headerName: "Actions",
            field: "Actions",
            headerClass: "ag-custom-header",
            cellRendererFramework: GridActions,
            pinned: "right",
            maxWidth: 150,
            minWidth: 100,
        },
    ]);

    useEffect(() => {
        localStorage.removeItem('editQuestionSetId');
        localStorage.removeItem('copyQuestionSetId');
    }, [])

    useEffect(() => {
        getQuestionSetList();
    }, [skip, itemsCountPerPage])


    function GridActions(props) {
        const status = () => {
            setOpen(props.data)
        }
        const edit = (e) => {
            localStorage.setItem("editQuestionSetId", props.data.id);
            history.push("/Home/QuestionSet/add-question-set");
        }
        const copy = (e) => {
            localStorage.setItem("copyQuestionSetId", props.data.id);
            history.push("/Home/QuestionSet/add-question-set");
        }

        return (
            <ul className="trigger-div mb-0 px-0">
                <i className="mx-4 fas fa-pencil-alt color-orange pointer" onClick={edit} data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"></i>
                <i className="mx-4 fas fa-trash color-orange pointer" onClick={status} data-bs-toggle="tooltip" data-bs-placement="top" title="Delete"></i>
            </ul>
        );
    }
    const setOpen = (data) => {
        handleShow();
        setQuestionSetName(data.name);
        setQuestionSetId(data.id);
        setQuestionSetStatus(data.status)
    };
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    };
    const containerStyle = {
        boxSizing: "border-box",
        height: rowData.length * messages.GridRowheight + messages.GridHeaderheight,
        minHeight: 150,
        width: "calc(100%)",
    };
    function GridActionsElement(props) {
        return (
            <div>
                {props.data.name}
            </div>
        );
    }

    const handlePageChange = (pageNumber) => {

        setActivePage(pageNumber);
        setSkip(pageNumber * itemsCountPerPage.value - itemsCountPerPage.value);
    };
    const deleteFunction = (id) => {
        axios.post("/QuestionSets/deleteQuestionSet", { editQuestionSetId: id }).then((res) => {
            if (!res.data.errorCode) {
                notify(messages.ToastSuccess, messages.deleteSuccess);
                getQuestionSetList()
                handleClose()
            }
            else {
                notify(messages.ToastError, messages.somethingWentWrong)
                handleClose()
            }
        })
    }
    const getQuestionSetList = () => {
        const url = "/QuestionSets/getQuestionSetList";
        setSpinner(true);
        axios.post(url, {
            skip: skip,
            limit: itemsCountPerPage.value,
            searchContent: searchContent,
            fields: ["id", "name", "questions", "status"],
            showTotalCount: showTotalCount
        }).then((res) => {
            if (res.data) {
                setSpinner(false);
                let rows = [];
                let data = res.data.items;
                for (let i in data) {
                    let questions = data[i].questions;
                    let questionLabels = [];
                    for (let k = 0; k < questions.length; k++) {
                        questionLabels.push(questions[k].id)
                    }
                    let item = {};
                    item.id = data[i].id;
                    item.name = data[i].name;
                    item.question = questionLabels.length;
                    item.status = data[i].status;
                    rows.push(item);
                }
                setRowData(rows);

            }
            setTotalCount(res.data.totalCount)
        }).catch((error) => {
            console.log(error);
            setSpinner(false);
        })
    }

    return (
        <Container fluid className="px-0 body-component">
            <ReactTitle title="Home-Questionnaires" />
            <ActionConfirmationPopup
                handleClose={handleClose}
                handleShow={handleShow}
                setShow={setShow}
                show={show}
                message={(show && questionSetStatus === 1 && messages.deleteQuestionSetMessage + ` "${questionSetName}" question set?`)}
                action={show && questionSetStatus === 1 && messages.delete}
                deactivateFunction={() => deleteFunction(questionSetId)}
            />
            <div className="container-fluid mt-3">
                <div className="row">
                    <p className="font-bold mb-1"> Manage Questionnaire</p>
                </div>
                <hr />
                <div className="row my-5">
                    <GridFilter
                        role={role}
                        entries={true}
                        search={true}
                        restore={false}
                        itemsCountPerPage={itemsCountPerPage}
                        setItemsCountPerPage={(e) => {
                            setSpinner(true)
                            setSkip(0)
                            setItemsCountPerPage(e);
                            setSpinner(false)
                        }}
                        setStatus={setStatus}
                        status={status}
                        addBtn={true}
                        addBtnPath={"/Home/QuestionSet/add-question-set"}
                        ajaxCall={getQuestionSetList}
                        setSearchContent={(e) => {
                            setSpinner(true)
                            setSkip(0)
                            setSearchContent(e);
                            setSpinner(false)

                        }}
                        tooltipTitle={"Questionnaire"}
                        setSkip={setSkip}
                        setActivePage={setActivePage}
                    />
                </div>
                <div className="container-fluid grid-list-div mb-3 ">
                    <div className=" organization-table">
                        <div className="ag-theme-alpine application-grid">
                            {spinner ? (<Spinner />) :
                                (<ReactGrid
                                    id="requestConsultGrid"
                                    gridId="Request-consult-List"
                                    containerStyle={containerStyle}
                                    columnDefs={columnDefs}
                                    rowData={rowData}
                                />)}
                        </div>
                        {totalCount > 10 ? (
                            <div className="row mb-5">
                                <div className="col-sm-6 font-bold mt-2">
                                    <ShowEntries
                                        totalCount={totalCount}
                                        itemsCountPerPage={itemsCountPerPage.value}
                                        activePage={activePage}
                                    />
                                </div>
                                <div className="col-md-6 pagination-component">
                                    <Pagination
                                        activePage={activePage}
                                        itemsCountPerPage={itemsCountPerPage.value}
                                        totalItemsCount={totalCount}
                                        pageRangeDisplayed={2}
                                        onChange={handlePageChange}
                                        itemClass="pagination-item"
                                        linkClass="pagination-link"
                                        prevPageText="Previous"
                                        nextPageText="Next"
                                    />
                                </div>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default Questionnaire
