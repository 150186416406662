import axios from "axios";
import { Component } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import Select from "react-select";
import { messages } from "../utils/constants";
import { notify } from "../utils/utility";
const { cloneDeep } = require("lodash");

class DownloadStudentsPdf extends Component {
  constructor(props) {
    super(props);
    this.state = {
      student: messages?.trainingReportOptions[0],
      selectedStudent: [],
      withoutEmailSelectedStudent: []
    };
    this.submitHandler = this.submitHandler.bind(this);
  }
  componentDidMount() {
    this.studentOnChange(cloneDeep(this.props.allStudent) || []);
  }
  submitHandler() {
    let value,
      changedUserEmail = this.state.selectedStudent.map((student) => {
        return { macId: student.value, email: student._email };
      });
    if (this.state.student.value === "All") {
      value = "All";
    } else {
      value = this.state.selectedStudent.map((macId) => macId.value);
    }
    this.props.setSpinner(true);
    axios
      .post("/Training/generateMultipleTrainingReport", {
        trainingId: this.props.trainingId,
        macIds: value,
        changedUserEmail: changedUserEmail,
        combinedView: this.props.combineLogFlag,
        graphImgShow: this.props.graphImgShow,
        sharing: this.props.sharing,
      })
      .then(
        function (response) {
          this.props.setSpinner(false);
          // handle success
          let data = response.data ? response.data : {};
          if (!data.errorCode) {
            let trainingReportLink = messages.WebUrl;
            if (this.props.sharing) {
              notify(messages.ToastSuccess, messages.MailSent);
              this.props?.getTrainingDetails?.();
            } else if (data?.pdf) {
              trainingReportLink += "/training-reports/" + data.pdf;
            } else if (data?.zip) {
              trainingReportLink += "/training-reports-zips/" + data.zip;
            }
            if (!this.props.sharing) {
              window.open(trainingReportLink);
            }
            this.props.onHide();
          } else {
            notify(messages.ToastError, messages.ServerNotResponding);
            this.props.onHide();
          }
        }.bind(this)
      )
      .catch(
        function (error) {
          console.error("error", error);
          this.props.setSpinner(false);
        }.bind(this)
      );
  }
  studentOnChange(selectedOption) {
    this.setState({
      // selectedStudent: selectedOption?.filter((inst) => inst.email && inst.email.match(messages.RegexForEmail)) || [],
      selectedStudent: selectedOption,
    });
  }
  changeStudentEmail(value, index) {
    let _selectedStudent = this.state.selectedStudent;
    if (_selectedStudent[index]) {
      _selectedStudent[index]._email = value;
      this.setState({
        selectedStudent: _selectedStudent,
      });
    }
  }
  render() {
    const disabled =
      !this.state.student?.value ||
      !this.state.selectedStudent?.length ||
      this.props.sharing && this.state.selectedStudent?.filter(
        (inst) =>
          !inst._email ||
          (inst._email && !inst._email.match(messages.RegexForEmail))
      )?.length;
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        size="md"
        centered
      >
        <Modal.Header className="font-bold" closeButton>
          {this.props.sharing ? "Share Report" : "Download Report"}
        </Modal.Header>
        <Modal.Body>
          <Col lg={12}>
            <div className="d-flex students-select-type my-2 justify-content-between">
              <span className="font-bold mt-2">Select type:&nbsp;&nbsp;</span>
              <Select
                className="SelectBox w-50"
                options={messages.trainingReportOptions.map((e, key) => ({
                  id: key,
                  label: e.label,
                  value: e.value,
                }))}
                value={this.state.student}
                onChange={(selectedOption) => {
                  this.setState({
                    student: selectedOption,
                  });
                  if (selectedOption.value === "All") {
                    this.studentOnChange(
                      cloneDeep(this.props.allStudent) || []
                    );
                  } else {
                    this.setState({
                      selectedStudent: [],
                      withoutEmailSelectedStudent: []
                    })
                  }
                }}
              />
            </div>
          </Col>
          {this.state.student?.value === "Selected-user" ? (
            <Col lg={12}>
              <div className="d-flex students-select my-2 justify-content-between">
                <span className="font-bold mt-2">
                  Select students:&nbsp;&nbsp;
                </span>
                <Select
                  isMulti
                  className="SelectBox w-50"
                  options={cloneDeep(this.props.allStudent)}
                  value={this.state.selectedStudent}
                  onChange={(selectedOption) =>
                    this.studentOnChange(selectedOption)
                  }
                />
              </div>
            </Col>
          ) : (
            ""
          )}
          <Col lg={12}>
            {this.props.sharing &&
              this.state.selectedStudent.map((student, index) => {
                return (
                  <div
                    className="d-flex students-select my-2 justify-content-between"
                    key={"student-" + index}
                  >
                    <span className="font-bold mt-2">{student.label}</span>
                    <input
                      type="text"
                      defaultValue={student.email}
                      className="form-control w-50"
                      placeholder={`Enter ${student.label} email`}
                      onChange={(e) =>
                        this.changeStudentEmail(e.target.value, index)
                      }
                      value={student._email}
                    />
                  </div>
                );
              })}
          </Col>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={() => this.submitHandler()}
            disabled={disabled}
            className="saveBtn m-1 submitSaveBtn"
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}
export default DownloadStudentsPdf;
