import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { messages } from "../utils/constants";
import { Container } from "react-bootstrap";
import Spinner from "../spinner/spinner";
import ReactGrid from "../grid/reactGrid";
import ShowEntries from "../utils/show-entries";
import Pagination from "react-js-pagination";
import GridFilter from "../grid/grid-filter";
import { toDefaultDateFormat, durationCalculate } from "../utils/utility";
import axios from "axios";
import { ReactTitle } from "react-meta-tags";
const GridActions = (props) => {
  const history = useHistory();
  const graphView = (e) => {
    localStorage.setItem("trainingLogId", props.data.trainingId);
    localStorage.setItem("trainingLogName", props.data.trainingName || "");
    history.push("/Home/Training-log-viewer");
  };
  const assessmentList = (e) => {
    localStorage.setItem("trainingLogId", props.data.trainingId);
    localStorage.setItem("trainingLogName", props.data.trainingName || "");
    history.push("/Home/Trainings/Assessments");
  };
  return (
    <ul className="trigger-div mb-0 px-0">
      <i
        className="mx-4 fas fa-chart-bar color-orange pointer"
        onClick={graphView}
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title="Log Viewer"
      ></i>
      <i
        className="mx-4 fas p-2 far fa-newspaper color-orange pointer"
        onClick={assessmentList}
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title="Assessments"
      ></i>
    </ul>
  );
};

const TrainingList = () => {
  const [spinner, setSpinner] = useState(false);
  const [itemsCountPerPage, setItemsCountPerPage] = useState(
    messages.GridRowCountOptions[0]
  );
  const userRole = localStorage.getItem("role");
  const [showTotalCount, setShowTotalCount] = useState(true);
  const [searchContent, setSearchContent] = useState("");
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [totalCount, setTotalCount] = useState();
  const [activePage, setActivePage] = useState(1);
  const [skip, setSkip] = useState(0);
  const [rowData, setRowData] = useState([]);
  const [instructors, setInstructors] = useState([]);
  const [instructor, setInstructor] = useState();

  useEffect(() => {
    if (+userRole === 2) {
      getInstructor();
    }
  }, []);

  const getInstructor = () => {
    const url = "/UserAccounts/getUserList";
    setSpinner(true);
    axios
      .get(url, {
        params: {
          organizationId: localStorage.getItem("organizationId"),
          instructor: true,
        },
      })
      .then(function (response) {
        setSpinner(false);
        let _instructors = [{
          label: "Self",
          value: localStorage.getItem("userId")
        }];
        let data = response.data.items;
        for (let i in data) {
          let item = {};
          item.label = data[i].name;
          item.value = data[i].id;
          _instructors.push(item);
        }
        setInstructor({
          value: _instructors[0].value,
          label: _instructors[0].label,
        });
        setInstructors(_instructors);
      })
      .catch((error) => {
        console.log(error);
        setSpinner(false);
      });
  };

  useEffect(() => {
    getTrainingList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip, itemsCountPerPage, from, to, instructor]);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    setSkip(pageNumber * itemsCountPerPage.value - itemsCountPerPage.value);
  };

  const getTrainingList = () => {
    const url = "/Training/getTrainingList";
    // const fromDate = from ? new Date(from) : "";
    // const toDate = to ? new Date(to) : "";
    setSpinner(true);
    axios
      .post(url, {
        skip: skip,
        limit: itemsCountPerPage.value,
        searchContent: searchContent,
        from: from ? new Date(from).toISOString() : "",
        to: to ? new Date(to).toISOString() : "",
        instructorId: instructor && instructor.value ? instructor.value : "",
        fields: [
          "id",
          "students",
          "endedAt",
          "createdAt",
          "trainingId",
          "instructorName",
          "instructorDetails",
          "name",
        ],
        includes: ["InstructorInfo"],
        showTotalCount: showTotalCount,
      })
      .then((res) => {
        if (res.data) {
          setSpinner(false);
          let rows = [];
          let data = res.data.items;
          for (let i in data) {
            const studentCount = (data[i].students || []).length;
            let item = {};
            item.TrainingId = data[i].trainingId;
            item.trainingId = data[i].id;
            item.TotalStudent = studentCount;
            item.trainingName = data[i].name;
            item.instructorName = data[i].instructorName || data[i]?.InstructorInfo?.name || "---";
            item.StartDate = data[i].createdAt
              ? toDefaultDateFormat(
                data[i].createdAt,
                messages.momentDateWithTime
              )
              : "";
            // item.EndDate = data[i].endedAt?toDefaultDateFormat(data[i].endedAt, messages.momentDateWithTime):"NA";
            item.EndDate =
              data[i].createdAt && data[i].endedAt
                ? durationCalculate(data[i]?.createdAt, data[i]?.endedAt)
                : "NA";
            rows.push(item);
          }
          setRowData(rows);
        }
        setTotalCount(res.data.totalCount);
      })
      .catch((error) => {
        console.log(error);
        setSpinner(false);
      });
  };

  const columnDefs = [
    {
      headerName: "Training Id",
      field: "trainingId",
      headerClass: "ag-custom-header",
      maxWidth: 300,
      minWidth: 300,
    },
    {
      headerName: "Training Name",
      field: "trainingName",
      headerClass: "ag-custom-header",
      maxWidth: 300,
      minWidth: 300,
    },
    {
      headerName: "Instructor Name",
      field: "instructorName",
      headerClass: "ag-custom-header",
      maxWidth: 300,
      minWidth: 300,
    },
    {
      headerName: "Total Student",
      field: "TotalStudent",
      headerClass: "ag-custom-header",
      minWidth: 150,
    },
    {
      headerName: "Start Date",
      field: "StartDate",
      headerClass: "ag-custom-header",
      minWidth: 200,
    },
    {
      headerName: `${messages.Duration}`,
      field: "EndDate",
      headerClass: "ag-custom-header",
      minWidth: 150,
    },
    {
      headerName: "Actions",
      field: "Actions",
      headerClass: "ag-custom-header",
      cellRendererFramework: GridActions,
      pinned: "right",
      maxWidth: 150,
      minWidth: 100,
    },
  ];
  const containerStyle = {
    boxSizing: "border-box",
    height: rowData.length * messages.GridRowheight + messages.GridHeaderheight,
    minHeight: 150,
    width: "calc(100%)",
  };
  return (
    <Container fluid className="px-0 body-component">
      <ReactTitle title="Home-Trainings" />
      <div className="container-fluid mt-3">
        <div className="row">
          <p className="font-bold mb-1">Trainings</p>
        </div>
        <hr />

        <div className="row my-5">
          <GridFilter
            entries={true}
            search={true}
            filter={true}
            instructorShow={true}
            role={userRole}
            instructors={instructors}
            ajaxCall={getTrainingList}
            itemsCountPerPage={itemsCountPerPage}
            instructor={instructor}
            setItemsCountPerPage={(e) => {
              setSpinner(true);
              setSkip(0);
              setItemsCountPerPage(e);
              setSpinner(false);
            }}
            setInstructor={(e) => {
              setSpinner(true);
              setSkip(0);
              setInstructor(e);
              setSpinner(false);
            }}
            // setStatus={setStatus}
            // status={status}
            restore={false}
            setSearchContent={(e) => {
              setSpinner(true);
              setSkip(0);
              setSearchContent(e);
              setSpinner(false);
            }}
            setTo={setTo}
            setFrom={setFrom}
            tooltipTitle={"Meter"}
            setSkip={setSkip}
            setActivePage={setActivePage}
          />
        </div>
        <div className="container-fluid grid-list-div mb-3 ">
          <div className=" organization-table">
            <div className="ag-theme-alpine application-grid">
              {spinner ? (
                <Spinner />
              ) : (
                <ReactGrid
                  id="requestConsultGrid"
                  gridId="Request-consult-List"
                  containerStyle={containerStyle}
                  columnDefs={columnDefs}
                  rowData={rowData}
                />
              )}
            </div>
            {totalCount > 10 ? (
              <div className="row mt-4 mb-5">
                <div className="col-sm-6 font-bold mt-2">
                  <ShowEntries
                    totalCount={totalCount}
                    itemsCountPerPage={itemsCountPerPage.value}
                    activePage={activePage}
                  />
                </div>
                <div className="col-md-6 pagination-component">
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={itemsCountPerPage.value}
                    totalItemsCount={totalCount}
                    pageRangeDisplayed={2}
                    onChange={handlePageChange}
                    itemClass="pagination-item"
                    linkClass="pagination-link"
                    prevPageText="Previous"
                    nextPageText="Next"
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default TrainingList;
