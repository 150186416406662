import React, { useEffect, useState } from "react";
import { messages } from "../utils/constants";
import Select from "react-select";
import { Link } from "react-router-dom";
function GridFilter(props) {
	var entries = props.entries ? true : false;
	var filter = props.filter ? true : false;
	var search = props.search ? true : false;
	var restore = props.restore ? true : false;
	var [status, setStatus] = useState("");
	var [searchContent, setSearchContent] = useState();
	const handleEnter = (e) => {
		if (e.keyCode === 13) {
			ajaxCall();
		}
	};
	const ajaxCall = () => {
		props.ajaxCall(searchContent);
	};
	const searchContentInputChange = (e) => {
		props.setSearchContent(e.target.value);
	};
	const statusChange = (e) => {
		props.setStatus(e.target.value);
	};
	useEffect(() => {
		setStatus("Activated");
	}, []);

	const customStyles = {
		option: (styles, state) => ({
			...styles,
			cursor: 'pointer',
		}),
		control: (styles) => ({
			...styles,
			cursor: 'pointer',
		}),
	}
	const filterAccordingClass = filter ? "col-lg-3 col-md-3 text-right mt-4 mt-md-0 ms-auto" : "col-lg-4 col-md-4 text-right mt-4 mt-md-0 ms-auto";
	return (
		<div className="page_heading_wrap">
			<div className="row">
				<div className="col-lg-3 col-md-3 col-sm-3">
					{entries ? (
						<div className="grid-row-count">
							<span className="label-content">Show</span>
							<span>
								<Select
									styles={customStyles}
									className="SelectBox "
									id="itemsCountPerPage"
									options={messages.GridRowCountOptions}
									isMulti={false}
									value={props.itemsCountPerPage}
									isSearchable={false}
									onChange={(select) => {
										props.setItemsCountPerPage(select);
										props.setSkip(0);
										props.setActivePage(1)
									}}
								/>
							</span>
							<span className="label-content">entries</span>
						</div>
					) : (
						""
					)}
				</div>
				<div className={filterAccordingClass}>
					{search ? (
						<div className="search_wrap">
							<span className="form-group">
								<div className="input-group">
									<span className="label-content">Search</span>
									<input
										type="text"
										className="form-control"
										id="search"
										onChange={searchContentInputChange}
										onKeyDown={handleEnter}
									/>
									<span className="input-group-addon pointer">
										<i
											className="fa fa-search"
											aria-hidden="true"
											onClick={ajaxCall}
										/>
									</span>
								</div>
							</span>
						</div>
					) : (
						""
					)}
				</div>
				<>{filter ? (
					<><div className="col-lg-3 col-md-3 text-right mt-4 mt-md-0 ms-auto">
						<div className="from_wrap">
							<span className="form-group">
								<div className="input-group">
									<span className="label-content">From</span>
									<input
										type="date"
										className="form-control"
										id="from"
										onChange={(e) => props.setFrom(e.target.value)}
									/>
								</div>
							</span>
						</div>
					</div>
						<div className="col-lg-3 col-md-3 text-right mt-4 mt-md-0 ms-auto">
							<div className="search_wrap">
								<span className="To-group">
									<div className="input-group">
										<span className="label-content">To</span>
										<input
											type="date"
											className="form-control"
											id="To"
											onChange={(e) => props.setTo(e.target.value)}
										/>
										{/*<Form.Control type="date" className={invalid.ExpiryDate ? " is-invalid" : ""} value={expiryDate} onChange={(e) => setExpiryDate(e.target.value)} />*/}
									</div>
								</span>
							</div>
						</div>
					</>) : (
					""
				)}</>
				{props.addBtn || props.addBtnPopup || restore? <div className="col-lg-4 col-md-4 statusRestoreDiv">
					<div className="row">
						{!restore && props.statusOptions ? (<div className="col-lg-10">
							<div className=" d-flex justify-content-lg-end">
								<span className="label-content">Status</span>
								<button
									className="btn  organization-status-button pointer"
									type="button"
								>
									<i className="fal fa-heart-rate"></i>
								</button>
								<select
									className="ml-2 mr-0 pl-2 w-100 organization-status-select font-bold"
									//   value={status}
									onChange={statusChange}
								>
									{/* <option value="1">Activated</option>
									<option value="2">Deactivated</option> */}
									{props.statusOptions.map((option, i) => (
										<option key={"grid-filer-" + i} value={i + 1}>{option}</option>
									))
									}
								</select>
							</div>
						</div>) : (+props.role === 1 ?
							(<div style={{ visibility: "hidden" }} className="col-lg-10 col-md-6 col-sm-6 col-xs-6">
								<div className="justify-content-lg-end"><i className="fas fa-power-off"></i></div></div>)
							: restore ? (<div className="col-lg-10 col-md-6 col-sm-6 col-xs-6 d-flex justify-content-lg-end restore">
								<div className="justify-content-lg-end d-flex restoreIconDiv pointer" type="button" data-bs-toggle="tooltip" data-bs-placement="top" title={"Restore Default " + props.tooltipTitle + "s" || ""}><div><i className="fas fa-power-off" onClick={props.restoreFunction}></i><div className="bottom-restore">Restore</div></div></div></div>) : (<div style={{ visibility: "hidden" }} className="col-lg-10 col-md-6 col-sm-6 col-xs-6 d-flex justify-content-lg-end restore">
								</div>))}
						<div className="col-lg-2 col-md-6 col-sm-6 col-xs-6 d-flex justify-content-end">
							{props.addBtn ? <Link
								to={props.addBtnPath}
								className="btn btn-secondary mr-sm-3 organization-plus-select pointer"
								type="button" data-bs-toggle="tooltip" data-bs-placement="top" title={"Add " + props.tooltipTitle || ""}
							>
								<i className="fas fa-plus"></i>
							</Link> : ""}
							{props.addBtnPopup ? <button
								onClick={()=>props.addBtnPopupClick()}
								className="btn btn-secondary mr-sm-3 organization-plus-select pointer"
								type="button" data-bs-toggle="tooltip" data-bs-placement="top" title={"Add " + props.tooltipTitle || ""}
							>
								<i className="fas fa-plus"></i>
							</button> : ""}
						</div>
					</div>
				</div> : ""
				}
				<div className="col-lg-4 col-md-4 col-sm-4 mt-3">
					{props.instructorShow && props.role == 2 ? (
						<div className="grid-instructor">
							<span className="label-content">Instructor</span>
							<span>
								<Select
									className="SelectBox "
									id="instructor"
									options={props.instructors}
									isMulti={false}
									value={props.instructor}
									isSearchable={false}
									onChange={(select) => {
										props.setInstructor(select);
										props.setSkip(0);
										props.setActivePage(1)
									}}
								/>
							</span>
						</div>
					) : (
						""
					)}
				</div>
			</div>
		</div>
	);
}
export default GridFilter;
