import React, { useState } from 'react'
import { messages } from "../utils/constants";
import { Container, Nav, Tab, Row, Col } from "react-bootstrap";
import Spinner from "../spinner/spinner";
import SupportMenual from './component/support-menual'
import SupportRefrenceLink from './component/support-reference-link'
import SupportTroubleShootingInfo from './component/support-troubleshooting-info'
import SupportVideos from './component/support-videos'
import SupportContactInfo from './component/support-contact-info'
import { ReactTitle } from "react-meta-tags";



const Support = () => {
    const role = localStorage.getItem("role");
    const [spinner, setSpinner] = useState(false);

    return (
        <Container fluid className="px-0">
            <ReactTitle title="Home-Support" />
            <div className="container-fluid mt-3">
                <div className="row">
                    <p className="font-bold mb-1"> {messages.Support}</p>
                </div>
                <hr />

                <div className="container-fluid mb-3 ">
                    <div className="row">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first" >
                            <div className="col-lg-5 pe-4">
                                <Nav variant="pills" className="flex-column supportPills">
                                    <Nav.Item className="pointer" >
                                        <Nav.Link eventKey="first">
                                            <Row className=" manualLeftTab">
                                                <Col lg={2}>
                                                    <div className="manualIcon">
                                                        <img src="/icons/manuals-s.svg" alt="reference" />
                                                    </div>
                                                </Col>
                                                <Col lg={10} className="manualLeftTabName">
                                                    <h6>{messages.Manuals}</h6>
                                                </Col>
                                            </Row>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="pointer">
                                        <Nav.Link eventKey="second">
                                            <Row className=" manualLeftTab">
                                                <Col lg={2}>
                                                    <div className="manualIcon">
                                                        <img src="/icons/reference-link-s.svg" alt="reference" />
                                                    </div>
                                                </Col>
                                                <Col lg={10} className="manualLeftTabName">
                                                    <h6>{messages.ReferenceLink}</h6>
                                                </Col>
                                            </Row>

                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="pointer">
                                        <Nav.Link eventKey="third">

                                            <Row className=" manualLeftTab">
                                                <Col lg={2}>
                                                    <div className="manualIcon">
                                                        <img src="/icons/Trouble-shooting-information-s.svg" alt="reference" />
                                                    </div>
                                                </Col>
                                                <Col lg={10} className="manualLeftTabName">
                                                    <h6>{messages.TroubleshootingInformation}</h6>
                                                </Col>
                                            </Row>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="pointer">
                                        <Nav.Link eventKey="fourth">

                                            <Row className=" manualLeftTab">
                                                <Col lg={2}>
                                                    <div className="manualIcon">
                                                        <img src="/icons/video-s.svg" alt="reference" />
                                                    </div>
                                                </Col>
                                                <Col lg={10} className="manualLeftTabName">
                                                    <h6>{messages.Videos}</h6>
                                                </Col>
                                            </Row>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="pointer">
                                        <Nav.Link eventKey="fifth">

                                            <Row className=" manualLeftTab">
                                                <Col lg={2}>
                                                    <div className="manualIcon">
                                                        <img src="/icons/Support-contact-s.svg" alt="reference" />
                                                    </div>
                                                </Col>
                                                <Col lg={10} className="manualLeftTabName">
                                                    <h6>{messages.SupportContact}</h6>
                                                </Col>
                                            </Row>
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                            <div className="col-lg-7 py-2 ps-4">

                                <Tab.Content className="supportPillsRight">
                                    <Tab.Pane eventKey="first">
                                        <SupportMenual />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <SupportRefrenceLink />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="third">
                                        <SupportTroubleShootingInfo />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="fourth">
                                        <SupportVideos />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="fifth">
                                        <SupportContactInfo />
                                    </Tab.Pane>

                                </Tab.Content>

                            </div>


                        </Tab.Container> </div>
                </div>
            </div >
        </Container >
    )
}

export default Support
