import { Modal } from 'react-bootstrap';
import { messages } from '../utils/constants';
import { Line, Chart, getElementsAtEvent } from 'react-chartjs-2';
import zoomPlugin from "chartjs-plugin-zoom";

Chart.register(zoomPlugin);

const Graph = (props) => {
    const unit = props.data && props.data.unit || "";
    const sensorLabel = props.data && props.data.sensorLabel || "";
    const meterName = props.data && props.data?.meterName || "";
    return (
        <Modal show={props.show} onHide={props.onHide} size="lg" centered>
            <Modal.Header closeButton>
                <p className='bold'>{meterName}</p>
            </Modal.Header>
            <Modal.Body>
                <Line
                    data={props.data}
                    getElementsAtEvent={(dataset, event) => {
                        event.stopPropagation();
                        let _image = dataset?.[0] && props.data.datasets[dataset[0].datasetIndex].images[dataset[0].index]
                        if (dataset.length === 0 || !_image) {
                        } else {
                            window.open(
                                messages.GraphImageUrl + _image,
                                "_blank",
                            );
                        }
                    }}
                    options={{
                        responsive: true,
                        cursor: "pointer",
                        plugins: {
                            legend: {
                                display: props.combineLogFlag ? true : false,
                                position: 'bottom',
                                labels: {
                                    usePointStyle: false,
                                }
                            },
                            zoom: {
                                pan: {
                                    enabled: true,
                                    mode: 'xy',
                                },
                                zoom: {
                                    wheel: {
                                        enabled: true,
                                    },
                                    pinch: {
                                        enabled: true
                                    },
                                    mode: 'xy',
                                }
                            },
                            tooltip: {
                                usePointStyle: true,
                                enabled: false,
                                external: function (context) {
                                    // console.log(context);
                                    // Tooltip Element
                                    let tooltipEl = document.getElementById('chartjs-tooltip-popup');

                                    // Create element on first render
                                    if (!tooltipEl) {
                                        tooltipEl = document.createElement('div');
                                        tooltipEl.id = 'chartjs-tooltip-popup';
                                        tooltipEl.innerHTML = '<table></table>';
                                        document.body.appendChild(tooltipEl);
                                    }

                                    // Hide if no tooltip
                                    const tooltipModel = context.tooltip;
                                    if (tooltipModel.opacity === 0) {
                                        tooltipEl.style.opacity = 0;
                                        return;
                                    }

                                    // Set caret Position
                                    tooltipEl.classList.remove('above', 'below', 'no-transform');
                                    if (tooltipModel.yAlign) {
                                        tooltipEl.classList.add(tooltipModel.yAlign);
                                    } else {
                                        tooltipEl.classList.add('no-transform');
                                    }

                                    // Set HTML & Data
                                    if (tooltipModel.body) {
                                        const { label: title = "",
                                            dataset: { pointName = [], graphValue = [], images = [] },
                                            formattedValue: reading = "",
                                            dataIndex
                                        } = tooltipModel.dataPoints[0];
                                        let label = "", label2 = graphValue[dataIndex] || "", label3 = "", iconClassName = "", image = images[dataIndex] || "";
                                        // console.log(image);
                                        switch (pointName[dataIndex]) {
                                            case "tag":
                                                label = "Tag";
                                                label3 = image && `<img src=${messages.GraphImageUrl + image} alt="graph-image" class="graph-image" width="200px" height="150px"/>` || "";
                                                iconClassName = "square bg-light-green";
                                                break;
                                            case "asked":
                                                label = "Question asked";
                                                iconClassName = "square bg-blue";
                                                break;
                                            case "correct":
                                                label = "Right answer";
                                                iconClassName = "square bg-green";
                                                break;
                                            case "incorrect":
                                                label = "Wrong answer";
                                                iconClassName = "square bg-red";
                                                break;
                                            case "alarm":
                                                label = "Alarm 2";
                                                iconClassName = "triangle-up";
                                                break;
                                            case "interIntermediate-alarm":
                                                label = "Alarm 1";
                                                iconClassName = "triangle-up";
                                                break;
                                            case "cancelled":
                                                label = "Cancelled";
                                                break;
                                            default:
                                                label = "";
                                        }
                                        const innerHtml = `
                                          <div class="training-chart-tooltip">
                                            <div> ${title}</div> 
                                            <div> Reading: ${reading} </div>
                                            <div><span class="${iconClassName}"></span> ${label}</div>
                                            <div> ${label2} </div>
                                            <div> ${label3} </div>
                                          </div>
                                      `;

                                        tooltipEl.querySelector('table').innerHTML = innerHtml;
                                    }
                                    const position = context.chart.canvas.getBoundingClientRect();

                                    // Display, position, and set styles for font
                                    tooltipEl.style.opacity = 1;
                                    tooltipEl.style.background = "#fff";
                                    tooltipEl.style.padding = "10px";
                                    tooltipEl.style.color = "#000";
                                    tooltipEl.style.zIndex = "1100";
                                    tooltipEl.style.position = 'absolute';
                                    tooltipEl.style.border = '1px solid #ccc';
                                    tooltipEl.style.borderRadius = '8px';
                                    tooltipEl.style.boxShadow = '1px 1px 3px rgba(50,50,50,0.6)';
                                    tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
                                    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                                    tooltipEl.style.padding = tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
                                    tooltipEl.style.pointerEvents = 'none';
                                }
                            },
                            title: {
                                display: true,
                                text: sensorLabel,
                                fontSize: 20
                            }
                        },
                        scales: {
                            yAxes: {
                                title: {
                                    display: true,
                                    text: unit,
                                    font: {
                                        size: 15
                                    }
                                },
                                ticks: {
                                    precision: 0
                                }
                            },
                            x: {
                                type: 'time',
                                time: {
                                    unit: 'minute'
                                },
                                title: {
                                    display: true,
                                    text: "Time",
                                    font: {
                                        size: 15
                                    }
                                }
                                // bounds:'data'
                            }
                        }
                    }}
                />
                <div className="showLegend mt-3">
                    <span><div className="square bg-light-green"></div> Tag</span>
                    <span><div className="square bg-blue"></div> Asked</span>
                    <span><div className="square bg-green"></div> Correct answer</span>
                    <span><div className="square bg-red"></div> Wrong answer</span>
                    <span><div className="triangle-up"></div> Alarm</span>
                    {/* <span><div className="triangle-up"></div> Alarm 1</span> */}
                </div>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>
    )
}
export default Graph;